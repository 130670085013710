<template>
    <div style="margin-top: 100px; text-align: center">
      <h2>Compra Certificaciones Zertú</h2>
      <p style="font-size: 20px">
        Gracias por tu compra {{ $store.state.username }}
      </p>
      <h2>Ahora podras encontrar tus certificaciones disponibles</h2>
      <br />
    </div>
  </template>
  <script>
  export default {
    data() {
        return{
  
        }
    },
  };
  </script>
  